<script setup>
 // Libraries
 import { storeToRefs } from 'pinia';

 // Stores
 import { useWindowStore } from '@/stores/window';

 // Composables
 import { getMonthDay, changeTimezone, getNowSafely } from '@/composables/helpers/date';
 import { getEnv } from '@/composables/helpers/getEnv';

 const {
  anchorId,
  headline,
  subheading,
  button,
  copy,
  useDate,
  pinnedTour,
  attributes,
  pinnedToursButtonLabel,
  blockIndex,
  tours,
 } = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  headline: {
   type: String,
   required: true,
  },
  subheading: {
   type: String,
   required: false,
   default: "What's next",
  },
  button: {
   type: Object,
   required: false,
  },
  copy: {
   type: String,
   required: false,
  },
  useDate: {
   type: Boolean,
   required: false,
  },
  pinnedTour: {
   type: Object,
   required: false,
  },
  tours: {
   type: Array,
   required: false,
  },
  pinnedToursButtonLabel: {
   type: String,
   required: false,
   default: 'tickets & times',
  },
  blockIndex: {
   type: Number,
   required: false,
  },
 });

 // Content Variables
 const apiKey = getEnv('builderApi');
 const builderEnv = getEnv('builderEnv');
 const safeNow = await getNowSafely();
 const tickets = ref(false);
 const cacheDuration = getEnv('cacheDuration', true) || 900;

 const postMeta = useState('postMeta');

 const cards = ref([]);
 const IDs = ref({
  include: [],
  exclude: [],
 });

 IDs.value.exclude.push(postMeta.value?.id);

 if (postMeta.value?.id) {
  IDs.value.exclude = [postMeta.value?.id];
 }

 const fakeTomorrow = ref(false);
 const date = computed(() => {
  //   return now.value;
  let timezone = changeTimezone(safeNow.value);
  let timezoneDate = new Date(timezone);
  let currHour = timezoneDate.getHours();
  if (currHour >= 18) {
   fakeTomorrow.value = true;
   let tomorrow = new Date(changeTimezone(safeNow.value));
   tomorrow.setDate(tomorrow.getDate() + 1);
   tomorrow.setHours(0, 0, 0, 0);
   return changeTimezone(tomorrow);
  }
  return changeTimezone(safeNow.value);
 });

 if (useDate) {
  const fetchTours = async () => {
   const params = {};
   let time = new Date(date.value);

   // Set tomorrow to 1am
   if (fakeTomorrow.value) time.setHours(1, 0, 0, 0);
   params.date = time.toISOString();

   if (postMeta.value?.model === 'tours') {
    params.id = postMeta.value?.id;
   }

   const { data } = await useAsyncData('fetchTours-city-tours', () => {
    return $fetch(`/api/filters/tours`, {
     params,
    });
   });
   return data;
  };

  const fullToursData = await fetchTours();
  const filterTours = fullToursData.value.tours
   .filter((tour) => {
    const hits = [];
    for (const performance of tour.performances) {
     const time = new Date(performance.performanceDateTime);

     let hit = false;

     if (time.getTime() > safeNow.value.getTime()) hit = true;

     if (!hit) {
      hits.push(false);
     } else {
      const ticketCount =
       fullToursData.value.tickets.find((ticket) => ticket.PerformanceId == performance.id)?.AvailableCount || 'n/a';

      if (ticketCount == 'n/a') hits.push(hit);
      else {
       if (ticketCount < 1) hits.push(false);
       else hits.push(true);
      }
     }
    }

    if (hits.includes(true)) return tour;
    else return false;
   })
   .filter(Boolean);

  let sortedTours = filterTours;
  if (pinnedTour?.id) {
   const pinnedTourIndex = filterTours.findIndex((tour) => tour.id === pinnedTour.id);
   if (pinnedTourIndex > -1) {
    const pinnedTour = filterTours.splice(pinnedTourIndex, 1);
    sortedTours = [pinnedTour[0], ...filterTours];
   }
  }

  cards.value.push(...sortedTours);
  tickets.value = fullToursData.value.tickets;
 } else if (tours && tours.length) {
  for (const index in tours) {
   const tour = tours[index].tour.id;
   IDs.value.include.push(tour);
  }

  const { data: cityToursData } = await useAsyncData('city-tours' + postMeta.value?.id + blockIndex, () =>
   $fetch('https://cdn.builder.io/api/v3/content/tours', {
    params: {
     apiKey,
     'query.data.environment': builderEnv,
     'query.id.$in': JSON.stringify(IDs.value.include),
     'query.id.$nin': JSON.stringify(IDs.value.exclude),
     limit: 100,
     cacheSeconds: cacheDuration,
    },
   })
  );

  if (cityToursData.value && cityToursData.value.results) {
   for (const index in cityToursData.value.results) {
    // Grab Tour
    const tour = cityToursData.value.results[index];
    // Move ID from include to exclude
    if (IDs.value.include.includes(tour.id)) {
     const indexOf = IDs.value.include.indexOf(tour.id);
     IDs.value.include.splice(indexOf, 1);
     IDs.value.exclude.push(tour.id);
    }
    // Push to cards
    cards.value.push(tour);
   }
  }
 }

 if (!useDate && cards.value.length < 3) {
  const randomNumbers = useState('random-numbers-city-tours-main' + postMeta.value?.id + blockIndex, () => []);

  const { data: backfillData } = await useAsyncData('backfill-city-tours' + postMeta.value?.id + blockIndex, () =>
   $fetch('https://cdn.builder.io/api/v3/content/tours', {
    params: {
     apiKey,
     'query.data.environment': builderEnv,
     'query.id.$nin': JSON.stringify(IDs.value.exclude),
     //  limit: 4 - cards.value.length,
     limit: 100,
     cacheSeconds: cacheDuration,
    },
   })
  );

  // Generate random numbers
  if (
   !randomNumbers.value.length &&
   backfillData.value &&
   backfillData.value.results &&
   backfillData.value.results.length
  ) {
   for (let i = 0; i < 4 - cards.value.length; i++) {
    let random = Math.floor(Math.random() * backfillData.value.results.length);
    while (randomNumbers.value.includes(random)) {
     random = Math.floor(Math.random() * backfillData.value.results.length);
    }
    randomNumbers.value.push(random);
   }
  }

  // Update backfillData
  for (const index in randomNumbers.value) {
   const tour = backfillData.value.results[randomNumbers.value[index]];
   cards.value.push(tour);
  }

  // if (backfillData.value && backfillData.value.results) {
  //  for (const index in backfillData.value.results) {
  //   // Grab Tour
  //   const tour = backfillData.value.results[index];
  //   // Push to cards
  //   cards.value.push(tour);
  //  }
  // }
 }

 // Variables
 const { innerWidth, innerHeight, deviceIs, scrollY } = storeToRefs(useWindowStore());

 const active = ref(0);
 const scrollStart = ref(0);
 const activeIndex = ref(0);
 const activeDrift = ref(1);
 const lastActiveDrift = ref(1);
 const itemsRef = ref([]);
 const itemsWrapperRef = ref(null);
 const scrollWidth = ref(570);
 const itemsWrapperRefDimensions = ref(null);
 const cardWidth = ref(570);
 const itemsScrollPosition = ref([]);
 const deltaX = ref(0);
 const leftOffset = ref(0);
 const emit = defineEmits(['updateActive']);
 const now = ref(Date.now());
 const lastScroll = ref(Date.now());

 const allowSnapTo = ref(true);
 const mouseDownTime = ref(null);
 const mouseUpTime = ref(null);
 const checkScrollInterval = ref(null);

 // Draggable functionality
 const pressed = ref(false);
 const start = ref(0);
 const startY = ref(0);
 const scrollTo = ref(0);
 const isScrolling = ref(false);
 const scrollingInterval = ref(false);

 // Never actually used
 const mousePosition = ref({
  inside: false,
  x: 0,
  y: 0,
 });

 const dragMargin = ref({
  left: 25,
  top: 0,
 });
 //  const isMouseInside = ref(false);
 const isDragMoving = ref(false);

 //  const hasAddedScrollListener = ref(false);
 //  const hasAddedTouchListeners = ref(false);

 // Computed
 const subheadline = computed(() => {
  return useDate == true ? `For ${getMonthDay(date.value)}` : subheading;
 });

 const snapType = computed(() => {
  let snap = 'x mandatory';

  if (pressed.value) snap = 'none';
  if (!allowSnapTo.value) snap = 'none';

  if (deviceIs.value != 'desktop') snap = 'x mandatory';
  return snap;
 });

 // Methods
 const getIndexHashmap = (loop = 0) => {
  if (loop > 10) return;
  // reset the array
  itemsScrollPosition.value = [];

  const items = itemsRef.value;
  const currentScroll = itemsWrapperRef.value?.scrollLeft;
  const type = typeof currentScroll;
  if (type !== 'number') {
   setTimeout(() => getIndexHashmap(loop + 1), 250);
   return;
  }

  const containerWidth = Math.min(1360, window.innerWidth * 0.9);
  const sideMargins = window.innerWidth - containerWidth;
  const leftOffset = sideMargins / 2;

  items.forEach((item, index) => {
   const { left } = item.getBoundingClientRect();
   itemsScrollPosition.value.push(left + currentScroll - leftOffset);

   if (index == items.length - 1) {
    const previousValue = itemsScrollPosition.value[itemsScrollPosition.value.length - 1];
    itemsScrollPosition.value.push(previousValue + cardWidth.value);
   }
  });

  const maxScrollToValue = itemsScrollPosition.value[itemsScrollPosition.value.length - 1];
  if (maxScrollToValue <= 0) setTimeout(() => getIndexHashmap(loop + 1), 250);
 };

 // left offset is determined by screen size
 const handleLeftOffset = () => {
  // left offset is 0 for anything smaller than tablet
  if (deviceIs.value != 'desktop') {
   leftOffset.value = 0;
   return;
  }
  const halfContainer = Math.min(1360 / 2, ((1360 / 1440) * innerWidth.value) / 2);
  // padding from lefthand side of container
  const padding = 96;
  leftOffset.value = innerWidth.value / 2 + padding - halfContainer;
 };

 const scrolling = () => {
  scrollTo.value = itemsWrapperRef.value.scrollLeft;

  // If not pressed, check how long scroll has been still
  if (!pressed.value) {
   if (checkScrollInterval.value) clearInterval(checkScrollInterval.value);

   checkScrollInterval.value = setInterval(() => {
    now.value = Date.now();
    const timeSinceLastScroll = now.value - lastScroll.value;
    const oneFrame = 16;
    const delta = 50 * oneFrame;
    if (timeSinceLastScroll > delta) {
     clearInterval(checkScrollInterval.value);
     allowSnapTo.value = true;
    }
   }, 16);

   lastScroll.value = now.value;
  }

  let value = Math.ceil(scrollTo.value / scrollWidth.value);
  if (value < 0) value = 0;
  if (value >= itemsScrollPosition.value.length) value = itemsScrollPosition.value.length - 1;
  if (value === active.value) return;

  active.value = value;
  emit('updateActive', active.value);

  // Just let scrollSnap do its thing on mobile
  if (deviceIs.value != 'desktop') return;
 };

 // If the time between mouse down and mouse up is less than 100ms, don't jump
 const handleItemClick = (index) => {
  if (mouseUpTime.value - mouseDownTime.value >= 249) return;
  if (index == activeIndex.value) {
   console.log('UGH!!!');
   const item = itemsRef.value[index];
   const link = item.querySelector('a');
   const url = link.getAttribute('href');
   window.location = url;
  } else {
   jumpTo(index);
  }
 };

 const jumpTo = (index) => {
  if (index < 0) index = 0;
  if (index >= itemsScrollPosition.value.length) index = itemsScrollPosition.value.length - 1;

  allowSnapTo.value = false;

  const leftScroll = itemsWrapperRef.value.scrollLeft;
  const isBeginning = leftScroll === itemsScrollPosition.value[index];
  const isEndRange =
   leftScroll >= itemsScrollPosition.value[index] - 10 && leftScroll <= itemsScrollPosition.value[index] + 10;
  const isEnd = index === itemsScrollPosition.value.length - 1 && isEndRange;
  const shouldOverrideAllowSnapTo = isEnd;

  if (shouldOverrideAllowSnapTo) {
   allowSnapTo.value = true;

   setTimeout(() => {
    allowSnapTo.value = true;
   }, 250);

   return;
  }

  itemsWrapperRef.value.scrollTo({
   left: itemsScrollPosition.value[index],
   behavior: 'smooth',
  });
 };

 const mouseDown = (e) => {
  // Just let scrollSnap do its thing on mobile
  if (deviceIs.value != 'desktop') return;

  mouseDownTime.value = Date.now();
  pressed.value = true;
  let clientX = e.touches ? e.touches[0].clientX : e.x;
  let clientY = e.touches ? e.touches[0].clientY : e.y;
  start.value = clientX - itemsWrapperRefDimensions.value.left;
  scrollStart.value = itemsWrapperRef.value.scrollLeft;
  startY.value = clientY;

  // itemsWrapperRef.value.style['scroll-snap-type'] = 'none';
  // itemsWrapperRef.value.style.cursor = 'grabbing';
 };

 const mouseUp = (e) => {
  // Just let scrollSnap do its thing on mobile
  if (deviceIs.value != 'desktop') return;

  if (e.touches) handleMouseEnter(false);
  // mousePosition.value.inside = false;

  mouseUpTime.value = Date.now();

  // Snap to nearest item smoothly
  const isDragging = Math.abs(deltaX.value) > 100;
  if (isDragging && mouseUpTime.value - mouseDownTime.value >= 250) {
   // if (isDragging) {
   const nearestItem = Math.round(scrollTo.value / scrollWidth.value);
   jumpTo(nearestItem);
  }

  allowSnapTo.value = false;
  pressed.value = false;
  // updateActive(active.value);
 };

 const handleDragMousePosition = () => {
  if (isDragMoving.value) return;
  if (isScrolling.value) return;

  // const { clientX, clientY } = event;

  const rect = itemsWrapperRef.value?.getBoundingClientRect();

  const x = mousePosition.value.x - rect.left; //x position within the element.
  const y = mousePosition.value.y - rect.top; //y position within the element.

  // Convert X and Y to percentage, where dead center is 0, far left is -50, far right is 50
  // const xPercent = (x / width) * 100 - 50;
  // const yPercent = (y / height) * 100 - 50;

  // Set drag margin based on x and y percentage
  dragMargin.value = {
   left: x,
   top: y,
  };
 };

 const mouseMove = (e) => {
  mousePosition.value.x = e.touches ? e.touches[0].clientX : e.clientX;
  mousePosition.value.y = e.touches ? e.touches[0].clientY : e.clientY;

  handleDragMousePosition(e);

  if (!allowSnapTo.value) return;
  let newScrollTo = 0;

  // Just let scrollSnap do its thing on mobile
  if (deviceIs.value != 'desktop') return;
  if (!pressed.value) return;

  let clientX = e.touches ? e.touches[0].clientX : e.x;
  let clientY = e.touches ? e.touches[0].clientY : e.y;

  // Get 15% of the screen height
  const fivePercentOfScreenHeight = innerHeight.value * 0.05;
  const fifteenPercentOfScreenHeight = innerHeight.value * 0.15;
  const userVerticalDrag = clientY - startY.value;
  const userVerticalDragDirection = userVerticalDrag > 0 ? 'down' : 'up';

  if (Math.abs(userVerticalDrag) < fivePercentOfScreenHeight) {
   // e.preventDefault();
  }

  // if (Math.abs(userVerticalDrag) > fifteenPercentOfScreenHeight) {
  // 	if (userVerticalDragDirection === 'up') {
  // 		scrollIntoView(speakerBios.value);
  // 		return;
  // 	}
  // }

  const movementX = clientX - itemsWrapperRefDimensions.value.left;
  deltaX.value = movementX - start.value;

  // If a user is scrolling vertically, don't scroll horizontally

  // const scrollStart = parseInt(itemsScrollPosition.value[active.value]);
  newScrollTo = scrollStart.value - deltaX.value;

  // set a max scroll
  const maxScrollToValue = itemsScrollPosition.value[itemsScrollPosition.value.length - 1];
  if (newScrollTo >= maxScrollToValue) newScrollTo = maxScrollToValue;

  itemsWrapperRef.value?.scrollTo({
   left: newScrollTo,
   behavior: 'instant',
  });

  // itemsWrapperRef.value.scrollLeft = newScrollTo;

  // Set scroll to value once!
  scrollTo.value = newScrollTo;
 };

 const moveDragStartTimeStamp = ref(null);
 const moveDragToMousePosition = (loop = 0) => {
  if (isScrolling.value) return;
  const now = Date.now();
  const duration = 500;

  const timeDiff = now - moveDragStartTimeStamp.value;

  if (timeDiff >= duration) {
   isDragMoving.value = false;
   return;
  }

  const progress = timeDiff / duration;

  const frozenDragPosition = JSON.parse(JSON.stringify(dragMargin.value));
  const rect = itemsWrapperRef.value?.getBoundingClientRect();

  let xDiff = 0;
  let yDiff = 0;

  if (mousePosition.value.inside === false && rect) {
   // Return to center
   const { width, height } = rect;
   const halfHeight = height / 2;
   const threeQuartersWidth = width / 4;
   const halfWidth = threeQuartersWidth * 3;

   xDiff = halfWidth - frozenDragPosition.left;
   yDiff = halfHeight - frozenDragPosition.top;
  } else if (rect) {
   const newX = mousePosition.value.x - rect.left;
   const newY = mousePosition.value.y - rect.top;

   xDiff = newX - frozenDragPosition.left;
   yDiff = newY - frozenDragPosition.top;
  }

  //  Math.floor(start + (end - start) * progress);
  const xStep = Math.floor(frozenDragPosition.left + xDiff * progress);
  const yStep = Math.floor(frozenDragPosition.top + yDiff * progress);

  // const xStep = xDiff / steps;
  // const yStep = yDiff / steps;

  // Only update if not scrolling
  dragMargin.value = {
   left: xStep,
   top: yStep,
  };

  requestAnimationFrame(() => moveDragToMousePosition());
 };

 const handleMouseEnter = (isInside) => {
  mousePosition.value.inside = isInside;
  isDragMoving.value = true;
  moveDragStartTimeStamp.value = Date.now();
  moveDragToMousePosition(0);
 };

 // TODO: Make this a composable
 const testPassiveSupport = () => {
  let supportsPassive = false;
  try {
   const opts = Object.defineProperty({}, 'passive', {
    get() {
     supportsPassive = true;
    },
   });
   window.addEventListener('test', null, opts);
   window.removeEventListener('test', null, opts);
  } catch (e) {}
  return supportsPassive;
 };

 const setup = (loop = 0) => {
  const supportsPassive = testPassiveSupport();
  const options = supportsPassive ? { passive: true } : false;

  destroy(options);

  // Stop if no items wrapper and we've tried 10 times
  if (loop > 10) return;

  if (!itemsWrapperRef.value) {
   // Try again in 100ms
   setTimeout(() => {
    setup(loop + 1);
   }, 100);
   return;
  }

  // Setup grab initial position;
  const rect = itemsWrapperRef.value?.getBoundingClientRect();
  const { width, height } = rect;
  const halfHeight = height / 2;
  const threeQuartersWidth = width / 4;
  const halfWidth = threeQuartersWidth * 3;
  setTimeout(() => {
   dragMargin.value = {
    left: halfWidth,
    top: halfHeight,
   };
  }, 150);

  itemsWrapperRefDimensions.value = itemsWrapperRef.value?.getBoundingClientRect();
  cardWidth.value = itemsRef.value?.[0]?.getBoundingClientRect()?.width;
  if (cardWidth.value === 0) cardWidth.value = 570;

  getIndexHashmap();
  handleLeftOffset();

  scrollWidth.value = cardWidth.value;

  itemsWrapperRef.value.addEventListener('scroll', scrolling);

  // mouse events
  itemsWrapperRef.value.addEventListener('mousedown', (e) => mouseDown(e), options);
  itemsWrapperRef.value.addEventListener('mouseup', (e) => mouseUp(e), options);
  itemsWrapperRef.value.addEventListener('mousemove', (e) => mouseMove(e), options);
  itemsWrapperRef.value.addEventListener('mouseenter', () => handleMouseEnter(true), options);
  itemsWrapperRef.value.addEventListener('mouseleave', () => handleMouseEnter(false), options);

  itemsWrapperRef.value.addEventListener('touchstart', (e) => mouseDown(e), options);
  itemsWrapperRef.value.addEventListener('touchend', (e) => mouseUp(e), options);
  itemsWrapperRef.value.addEventListener('touchmove', (e) => mouseMove(e), options);
 };

 const destroy = (options = false) => {
  try {
   itemsWrapperRef.value.removeEventListener('scroll', () => scrolling(), options);
   itemsWrapperRef.value.removeEventListener('mousedown', () => mouseDown(), options);
   itemsWrapperRef.value.removeEventListener('mouseup', () => mouseUp(), options);
   itemsWrapperRef.value.removeEventListener('mousemove', () => mouseMove(), options);
   itemsWrapperRef.value.removeEventListener('mouseenter', () => handleMouseEnter(true), options);
   itemsWrapperRef.value.removeEventListener('mouseleave', () => handleMouseEnter(false), options);

   itemsWrapperRef.value.removeEventListener('touchstart', () => mouseDown(), options);
   itemsWrapperRef.value.removeEventListener('touchend', () => mouseUp(), options);
   itemsWrapperRef.value.removeEventListener('touchmove', () => mouseMove(), options);
  } catch (e) {
   // console.error(e);
  }
 };

 // Lifecycle
 onMounted(() => {
  setup();
 });

 useNuxtApp().hook('page:transition:finish', async () => {
  setup();
 });

 onBeforeRouteLeave(() => {
  const supportsPassive = testPassiveSupport();
  const options = supportsPassive ? { passive: true } : false;

  destroy(options);
 });

 // Watchers
 watch(innerWidth, () => {
  setup();
 });

 const cheatTimeout = ref(null);
 const flipTheSwitch = () => {
  if (pressed.value) return;
  allowSnapTo.value = true;
 };
 watch(scrollTo, (value) => {
  lastActiveDrift.value = activeDrift.value;

  // Set active index based on scroll position
  activeIndex.value = Math.ceil(value / scrollWidth.value);

  // Stop if below 0 or above max
  if (activeIndex.value < 0) activeIndex.value = 0;
  if (activeIndex.value >= itemsScrollPosition.value.length - 2) {
   activeIndex.value = itemsScrollPosition.value.length - 2;
  }

  // Set active drift based on scroll position
  const baseDrift = (value % scrollWidth.value) / scrollWidth.value;

  const stillOffset = !pressed.value && baseDrift == 0 ? 1 : 0;
  const normalizedDrift = Math.min(baseDrift + stillOffset, 1);

  activeDrift.value = normalizedDrift;
  if (activeDrift.value <= 0 && activeIndex.value == 0) activeDrift.value = 1;

  const maxScrollToValue = itemsScrollPosition.value[itemsScrollPosition.value.length - 1];
  if (value >= maxScrollToValue) activeDrift.value = 1;

  // allowSnapTo.value = true;
  if (cheatTimeout.value) clearTimeout(cheatTimeout.value);
  cheatTimeout.value = setTimeout(flipTheSwitch, 250);
 });

 watch(scrollY, () => {
  isScrolling.value = true;
  clearTimeout(scrollingInterval.value);
  scrollingInterval.value = setTimeout(() => {
   isScrolling.value = false;
   handleMouseEnter(mousePosition.value.inside);
  }, 250);
 });
</script>

<template>
 <section class="block block-city-tours bg-white">
  <div class="grid container">
   <!-- <div class="container"> -->
   <!-- <div class="head"> -->
   <ProjectTitle v-if="headline" :title="headline" class="" />
   <div class="text-wrapper">
    <MessHtml v-if="subheadline" :html="subheadline" class="h3 date" tag="h3" />
    <MessHtml v-if="copy" :html="copy" class="body" />
    <ProjectButton v-if="button" :label="button.label" :link="button.url" :title="button.title" class="red container" />
    <!-- </div> -->
    <!-- </div> -->
   </div>
  </div>

  <div class="mess-carousel-draggable">
   <div class="fsc-wrapper">
    <ul
     class="carousel-inner cheating-left"
     ref="itemsWrapperRef"
     :style="`
            --active-drift: ${activeDrift}; 
            --card-width: ${cardWidth}px;
            --cursor: ${pressed ? 'grabbing' : 'grab'};
            scroll-snap-type: ${snapType};
      `"
    >
     <li class="carousel-item cheat-left" ref="cheatLeft">
      <div></div>
     </li>
     <template v-if="cards && cards.length">
      <li v-for="(card, index) in cards" :key="card.id" class="carousel-item" ref="itemsRef">
       <div @click="handleItemClick(index)" class="tour-item">
        <CityToursCard
         :card="card"
         :index="index"
         :scrollTo="scrollTo"
         :leftOffset="leftOffset"
         :activeIndex="activeIndex"
         :activeDrift="activeDrift"
         :button="useDate ? false : true"
         :buttonLabel="pinnedToursButtonLabel"
         :tickets="tickets"
        />
       </div>
      </li>
     </template>
     <li class="carousel-item cheat-right"><div></div></li>
    </ul>
   </div>

   <ProjectSvg type="drag-button" />
  </div>
 </section>
</template>

<style lang="scss">
 .block-city-tours {
  .title-wrapper {
   grid-column: 1 / -1;
   grid-row: 1 / span 2;

   @media (min-width: $mobile) {
    grid-column: 1 / 6;
   }

   @media (min-width: $tablet) {
    grid-column: 1 / 7;
    .h1 {
     margin-bottom: 0;
    }
   }
  }
  .text-wrapper {
   grid-column: 2 / span 3;
   justify-self: start;
   grid-row: 3;
   padding: 0 0 4rem 0;
   @media (min-width: $mobile) {
    grid-row: 2 / span 3;
    grid-column: 5 / span 4;
   }
   @media (min-width: $tablet) {
    grid-column: 8 / span 5;
   }
   .body {
    margin-bottom: 1.5rem;
   }
  }
  .mess-button {
   width: fit-content;
  }
  .mess-carousel-draggable {
   //  --half-container: min(680px, 45vw);
   //  --left-offset: calc(50vw - var(--half-container));

   --side-margins: calc(100vw - min(1360px, 90vw));
   //  --cheat-left-width: calc(var(--side-margins) / 2);
   --left-offset: calc(var(--side-margins) / 2);

   position: relative;
   width: calc(100%);
   height: 100%;

   .mess-svg[data-type='drag-button'] {
    position: absolute;
    top: 50px;
    right: 15px;
    z-index: 2;
    width: 60px;
    user-select: none;
    pointer-events: none;
    @media (min-width: $tablet) {
     top: 120px;
     right: 30px;
     z-index: 2;
     width: 89px;
    }
   }

   .fsc-wrapper {
    position: relative;

    .carousel-inner {
     display: flex;
     flex-wrap: nowrap;
     // overflow: hidden;
     overflow-y: hidden;
     overflow-x: scroll;
     -webkit-overflow-scrolling: touch;
     scroll-snap-type: x mandatory;
     scroll-behavior: smooth;
     -webkit-overflow-scrolling: touch;
     -ms-overflow-style: none;
     align-items: flex-start;
     transition: height 350ms ease;
     position: relative;
     padding-bottom: 40px;
     scroll-padding-left: var(--left-offset);

     scrollbar-width: none;

     &::-webkit-scrollbar {
      display: none;
     }

     .carousel-item {
      scroll-snap-align: start;
      cursor: var(--cursor);

      .tour-item {
       width: min(570px, 80vw);
      }
     }
    }
   }

   // Used to cheat the left padding
   --half-container: min(calc(1360px / 2), calc((1360 / 1440 * 100vw) / 2));

   .bio-animation-enter-active,
   .bio-animation-leave-active {
    transition: 0.5s ease-in-out;
   }
   .bio-animation-leave-active {
    position: absolute !important;
    z-index: 2;
    top: 0;
    width: 100%;
   }

   .bio-animation-enter-from,
   .bio-animation-leave-to {
    opacity: 0;
   }

   .cheating-left {
    .cheat-left {
     width: var(--left-offset);
     div {
      width: var(--left-offset);
     }
    }
    .cheat-right {
     div {
      --cheat-width: calc(100vw - var(--left-offset) - var(--card-width) - 20px);
      width: var(--cheat-width);
     }
    }
   }

   @media (max-width: $tablet) {
    .fsc-wrapper {
     .carousel-inner {
      cursor: var(--cursor);
     }
    }
    .bullets {
     margin-bottom: 0;
     justify-content: center;
    }
   }

   @media (max-width: $mobile) {
    .fsc-wrapper {
     .carousel-inner {
      width: 100vw;
     }
    }
    .cheating-left {
     .cheat-left {
      width: 2rem;
     }
     .cheat-right {
      div {
       width: calc(var(--cheat-width));
      }
     }
    }
   }
  }
 }
</style>
